import SegmentClient from "clients/SegmentClient";

document.addEventListener("DOMContentLoaded", () => {
  let form = document.querySelector("form#elderly-care-guide") || null;
  let eventName = "care guide download";

  if (!form) {
    form = document.querySelector("form#funding-care-guide") || null;
    eventName = "fin care guide download";
  }

  if (!form) {
    form = document.querySelector("form#dementia-guide") || null;
    eventName = "dementia guide download";
  }

  if (!form) {
    form = document.querySelector("form#elderly-falls-guide") || null;
    eventName = "elderly falls guide download";
  }

  if (!form) {
    form =
      document.querySelector("form#games-for-the-elderly-workbook") || null;
    eventName = "games for the elderly workbook download";
  }

  if (form && form.length) {
    const email = form.querySelector("#mce-EMAIL");
    const tosAgreement = form.querySelector("#confirm");
    const downloadButton = form.querySelector("button");
    const emailError = form.querySelector("p.error.email");
    const tosAgreementError = form.querySelector("p.error.agreement");
    downloadButton.addEventListener("click", () => {
      emailError.style.display = "none";
      tosAgreementError.style.display = "none";
      const emailEntry = email.value.trim();
      if (tosAgreement.checked && emailEntry) {
        SegmentClient.trackEvent({
          event: eventName,
          eventCatergory: "lead acquisition",
          eventAction: eventName,
          eventLabel: "submit",
          lead_form: eventName,
          email: emailEntry
        });

        SegmentClient.trackEvent({
          event: "guide download",
          eventCategory: "subscribe",
          eventAction: "guide download",
          eventLabel: "submit"
        });
        form.submit();
      } else if (!emailEntry) {
        emailError.style.display = "block";
      } else if (!tosAgreement.checked) {
        tosAgreementError.style.display = "block";
      }
    });
  }

  const careGuideComplete =
    document.getElementById("elderly-care-guide-complete") || null;
  if (careGuideComplete) {
    setTimeout(() => {
      window.location.href =
        "https://static.supercarers.com/files/guides/supercarers-elderly-care-guide.pdf";
    }, 1000);
  }

  const fundingCareGuideComplete =
    document.getElementById("funding-care-guide-complete") || null;
  if (fundingCareGuideComplete) {
    setTimeout(() => {
      window.location.href =
        "https://static.supercarers.com/files/guides/supercarers-funding-care-guide.pdf";
    }, 1000);
  }

  const dementiaGuideComplete =
    document.getElementById("dementia-guide-complete") || null;
  if (dementiaGuideComplete) {
    setTimeout(() => {
      window.location.href =
        "https://static.supercarers.com/files/guides/a-guide-to-living-with-dementia.pdf";
    }, 1000);
  }

  const elderlyFallsGuideComplete =
    document.getElementById("elderly-falls-guide-complete") || null;
  if (elderlyFallsGuideComplete) {
    setTimeout(() => {
      window.location.href =
        "https://static.supercarers.com/files/guides/falls-in-the-elderly-guide.pdf";
    }, 1000);
  }

  const gamesWorkbookComplete =
    document.getElementById("games-for-the-elderly-workbook-complete") || null;
  if (gamesWorkbookComplete) {
    setTimeout(() => {
      window.location.href =
        "https://static.supercarers.com/files/guides/Brain-Games.pdf";
    }, 1000);
  }
});
